import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import "./TopHeader.css";
import leftlogo from "../../images/akunah-hr-logo.svg";
import Button from "../custom/button/Button";
import LanguageHandler from "../LanguageHandler";
import useAxios from "../../axiosinstance";
import useTranslation from "../customHooks/translations";
import { useSelector, useDispatch } from "react-redux";
import { s3UrlLink } from "../../utilities/S3Url";
import signOutIcon from "../../images/Sign out.svg";
import { useNavigate } from "react-router-dom";
import switchImg from "../../images/sidebar/transfer.png";
import { loginData } from "../../redux/actions";
import useAxiosUpload from "../../useAxiosUpload";
import TopHeaderbellicon from "../../images/TopHeaderbellicon.svg";
import document from "../../images/Icon/document_icon.svg";
import arrowRight from "../../images/arrow-bright.svg";
import contact from "../../images/contact.svg";
import tick from "../../images/tick.png";
import platform from "platform";
import { nominalTypeHack } from "prop-types";
import Modal from "react-bootstrap/Modal";
import smiley from "../../images/smiley_purple.svg";
import { getTimeDifference } from "../../utilities/DateFormate";
import Message from "../custom/toster/Message";

const TopHeader = ({ c }) => {
  let axiosInstance = useAxios();
  const axiosInstanceUpload = useAxiosUpload();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const [langData, setlangData] = useState([]);
  const translation = useTranslation();
  const login = useSelector((state) => state.loginPatient.arr[0]);
  const [isOpened, setIsOpened] = useState(false);
  const [modalOpenSmall, setModalOpenSmall] = useState(false);
  let akToken = localStorage.getItem("AkTOKENI");
  const [loginDatas, setLoginDatas] = useState([]);
  const [selectedImage, setSelectedImage] = useState(""); //login.ing_image

  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [showRightPopup, setShowRightPopup] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [isDropDownOpen, setISDropDownOpen] = useState(false);

  const popupRef = useRef(null);
  const getData = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getLanguages/${loginDatas.ak_id}`
      );
      console.log(result);
      if (result.status === 200) {
        setlangData(result.data.data);
      }
    } catch (err) {
      console.log(err.Messages);
    } finally {
      console.log("api response finally");
    }
  };

  //create initial menuCollapse state using useState hook
  // const [isOpened, setIsOpened] = useState(false);
  // const [chatPopup, setChatPopup] = useState(false);
  // const [editProfileShow, setEditProfileShow] = useState(false);
  // const [needHelpShow, setNeedHelpShow] = useState(false);
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [phone, setPhone] = useState("");
  // const [email, setEmail] = useState("");
  // const [timeZone, setTimeZone] = useState("");
  // const handleClose = () => setEditProfileShow(false);
  // const handleShow = () => setEditProfileShow(true);
  // const handleNeedHelpClose = () => setNeedHelpShow(false);
  // const handleNeedHelpShow = () => setNeedHelpShow(true);
  const logout_url = window.location.origin;
  // const handleFirstName = (e) => {
  //   setFirstName(e);
  // };
  // const handleLastName = (e) => {
  //   setLastName(e);
  // };
  // const handlePhone = (e) => {
  //   setPhone(e);
  // };
  // const handleEmail = (e) => {
  //   setEmail(e);
  // };
  // const handleTimeZone = (e) => {
  //   setTimeZone(e);
  // };
  // const userIconClick = () => {
  //   isOpened ? setIsOpened(false) : setIsOpened(true);
  // };
  // const handleChatPopup = () => {
  //   setNeedHelpShow(false);
  //   setChatPopup(!chatPopup);
  // };
  const [browser, setBrowser] = useState("");
  useEffect(() => {
    const deviceInfo = platform;
    setBrowser(deviceInfo.description.toLowerCase().replace(/\s/g, ""));
  }, []);
  const json = JSON.stringify({
    ak_id: loginDatas.ak_id,
    status: "login",
    browser: browser,
  });
  const RegisterLogin = async () => {
    try {
      const result = await axiosInstance.current.post(
        `extapp/Registerlogin`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      // setMessage(err.message || "Unexpected Error!");
      console.log(err.Messages);
      // setMessageType("error");
    } finally {
      console.log("api response finally");
    }
  };
  useEffect(() => {
    if (browser) {
      RegisterLogin();
    }
  }, [browser]);

  const getLogin = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/fetchsessions?ak_id=${loginDatas.ak_id}&browser=${browser}&resource_id`
      );
      if (result) {
        // console.log(result.data.data, "mydats")
      }
    } catch (err) {
      if (err.response.status === 400) {
        localStorage.removeItem("AkTOKEN");
        sessionStorage.removeItem("AkTOKEN");
        window.location.reload();
        keycloak.logout({ redirectUri: logout_url });
      }
    }
  };

  useEffect(() => {
    if (browser && loginDatas.ak_id) {
      const intervalId = setInterval(() => {
        getLogin();
      }, 30000);
      return () => clearInterval(intervalId);
    }
  }, [browser, loginDatas.ak_id]);
  let logdata = localStorage.getItem("AkTOKENI");
  const handleLogout = async () => {
    if (logdata) {
      try {
        const result = await axiosInstance.current.put(
          `extapp/Logout?ak_id=${loginDatas.ak_id}&browser=${browser}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (result.data.status) {
          localStorage.removeItem("AkTOKENI");
          // sessionStorage.setItem("isConsent","true");
          keycloak.logout({ redirectUri: logout_url });
        }
      } catch (err) {
        // setMessage(err.message);
        // setMessageType("error");
      } finally {
        console.log("api response finally");
      }
    } else {
      localStorage.removeItem("AkTOKENI");
      localStorage.removeItem("model");
      keycloak.logout({ redirectUri: logout_url });
    }
  };

  useEffect(() => {
    if (login) {
      setLoginDatas(login.patient);
      setSelectedImage(login.ing_image);
    }
  }, [login]);

  const handleConnectMe = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getDetailsOfPatient?patient_id=${login.patientInfo.ak_id}&use_for=patient`
      );
      if (result.status === 200) {
        if (result.data.status) {
          dispatch(loginData(result.data.data));
          sessionStorage.setItem(
            "lang_",
            result.data.data[0].patient.user_language
          );
          sessionStorage.setItem(
            "isConsent",
            result.data.data[0].patientInfo.isConsent
          );
        }
      }
    } catch (err) {
      console.log(err.Messages);
    } finally {
      console.log("api response finally");
    }
  };
  function onFileUpload(e) {
    const formData = new FormData();
    formData.append("profile_pic", selectedImage);
    // console.log(Object.keys(formData).length);
    // if (Object.keys(formData).length !== 0)
    axiosInstanceUpload.current
      .post(
        `extglobal/updateprofile?ak_id=${login.patientInfo.ak_id}`,
        formData
      )
      .then((response) => {
        if (response) {
          setSelectedImage(response.data.url);
          handleConnectMe();
        }
      })
      .catch((err) => {
        // setMessage(err.response.data.message);
        // setMessageType("error");
      });
  }

  useEffect(() => {
    if (typeof selectedImage === "object") {
      setMessage(false);

      onFileUpload();
    }
  }, [selectedImage]);

  const closeModalSmall = () => {
    setModalOpenSmall(false);
  };

  const userIconClick = () => {
    isOpened ? setIsOpened(false) : setIsOpened(true);
    closeModalSmall();
  };
  useEffect(() => {
    if (loginDatas) {
      getData();
    }
  }, [loginDatas]);

  const handlerSwitch = () => {
    localStorage.removeItem("AkTOKENI");
    localStorage.removeItem("model");
    dispatch(loginData([]));
    navigate("/");
    sessionStorage.setItem("isConsent", "true");
  };

  const lang = sessionStorage.getItem("lang_");

  const handlecontinue = async () => {
    navigate(`/scheduler-notification`);
    setShowRightPopup(false);
  };

  const [markAllRead, setMarkAllRead] = useState(false);
  // const ReadtoMark = async (res_id) => {
  //   try {
  //     const response = await axiosInstance.current.put(
  //       `/extapp/doctors/patient_resource?resource_id=${res_id}&ak_id=${login.patientInfo.ak_id}`
  //     );
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const handleMarkAllRead = () => {
    // ReadtoMark()
    // Update the state when the button is clicked
    setMarkAllRead(true);
  };

  const [notificationCount, setNotificationCount] = useState(0);
  const getNotification = async () => {
    setShowRightPopup(!showRightPopup);

    // setNotificationCount(notificationCount + 1);
  };
  useEffect(() => {
    const getNotification = async ()=>{
      try {
      const result = await axiosInstance.current
          .get(
            `extapp/doctors/patient_resource?akpt_id=${akToken}&limit=5&offset=0`
            // `extapp/doctors/DocNotification?ak_pt=${login.patientInfo.ak_id}`
          )
          if (result) {
            setNotificationData(result.data.data);
            setNotificationCount(result.data.total_notification_count);
            
            sessionStorage.setItem('notificationData', result.data.total_notification_count)
          }
      } catch (err) {
      
        sessionStorage.setItem('notificationData', "")
        console.log(err);
      }
    }
    getNotification();
   
  }, [akToken]);

  const dateFuture = (e) => {
    let isFutureDate;
    const givenDate = new Date(e).toDateString();
    const currentDate = new Date().toDateString();
    if (givenDate >= currentDate) {
      isFutureDate = "Pending";
    } else {
      isFutureDate = "Expired";
    }

    return isFutureDate;
  };
  const handleOpenModal = () => {
    setShowRightPopup(true);
  };
  const handleClose = () => {
    setShowRightPopup(false);
  };

  const handleOpen = (ak_dc, ak_pt, ak_ou, type, resource_id, status) => {
    // ReadtoMark(resource_id);
    axiosInstance.current.put(
      `/extapp/doctors/patient_resource?resource_id=${resource_id}&ak_id=${akToken}`
    ).then((res) => {
      if (status === "-1") {
        setMessage("This form has Expired");
        setMessageType("error");
        setTimeout(() => {
          setMessage(false);
        }, 3000);
      } else {
        // setTimeout(() => {
          if (type === 33) {
            window.location.href =
              process.env.REACT_APP_FORM_URL +
              "general-history" +
              `/${resource_id}?is_doctor_form=null&edit=true&status=3`;

          } else {
            window.location.href =
              process.env.REACT_APP_FORM_URL +
              "patient-personal-data" +
              `/${window.btoa(ak_pt)}` +
              `/${window.btoa(type)}?r=${window.btoa(resource_id)}`;
          }
          // window.location.href =
          //   process.env.REACT_APP_FORM_URL +
          //   "patient-personal-data" +
          //   `/${window.btoa(ak_pt)}` +
          //   `/${window.btoa(type)}?r=${window.btoa(resource_id)}`;
        // }, 1000);
      }
    }).catch((err) => {
      if (status === "-1") {
        setMessage("This form has Expired");
        setMessageType("error");
        setTimeout(() => {
          setMessage(false);
        }, 3000);
      } else {
        setTimeout(() => {
          if (type === 33) {
            window.location.href =
              process.env.REACT_APP_FORM_URL +
              "general-history" +
              `/${resource_id}?is_doctor_form=null&edit=true&status=3`;

          } else {
            window.location.href =
              process.env.REACT_APP_FORM_URL +
              "patient-personal-data" +
              `/${window.btoa(ak_pt)}` +
              `/${window.btoa(type)}?r=${window.btoa(resource_id)}`;
          }
          // window.location.href =
          //   process.env.REACT_APP_FORM_URL +
          //   "patient-personal-data" +
          //   `/${window.btoa(ak_pt)}` +
          //   `/${window.btoa(type)}?r=${window.btoa(resource_id)}`;
        }, 1000);
      }
    })


    if (status === "-1") {
      setMessage("This form has Expired");
      setMessageType("error");
      setTimeout(() => {
        setMessage(false);
      }, 3000);
    } else {
      setTimeout(() => {
        if (type === 33) {
          window.location.href =
            process.env.REACT_APP_FORM_URL +
            "general-history" +
            `/${resource_id}?is_doctor_form=null&edit=true&status=3`;

        } else {
          window.location.href =
            process.env.REACT_APP_FORM_URL +
            "patient-personal-data" +
            `/${window.btoa(ak_pt)}` +
            `/${window.btoa(type)}?r=${window.btoa(resource_id)}`;
        }
        // window.location.href =
        //   process.env.REACT_APP_FORM_URL +
        //   "patient-personal-data" +
        //   `/${window.btoa(ak_pt)}` +
        //   `/${window.btoa(type)}?r=${window.btoa(resource_id)}`;
      }, 1000);
    }
  };


  //
  const logoutTimeout = useRef(null);
  const logoutUser = () => {
    keycloak.logout({ redirectUri: logout_url });
  };
  const resetLogoutTimer = () => {
    clearTimeout(logoutTimeout.current);
    logoutTimeout.current = setTimeout(() => {
      if (!keycloak.authenticated) return;
      logoutUser();
    }, 900000);
  };
  const handleUserActivity = () => {
    resetLogoutTimer();
  };
  const setupUserActivityListeners = () => {
    // window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
  };
  const removeUserActivityListeners = () => {
    // window.removeEventListener('mousemove', handleUserActivity);
    window.removeEventListener('keydown', handleUserActivity);
    window.removeEventListener('scroll', handleUserActivity);
    window.removeEventListener('click', handleUserActivity);
  };
  useEffect(() => {
    setupUserActivityListeners();
    resetLogoutTimer();
    return () => {
      removeUserActivityListeners();
      clearTimeout(logoutTimeout.current);
    };
  }, [keycloak.authenticated]);
  return (
    <>
      {/* <div className="App"> */}
      <div className="">
        {message && <Message message={message} type={messageType} />}
        <div
          className="top__header"
          style={{ position: `${c ? "relative" : ""}` }}
        >
          <div className="nav_inner_left">
            <Link to="/" className="leftLogo">
              <img src={leftlogo} className="left-logo" alt="logo" />
            </Link>
          </div>
          <div className="nav_inner_search"></div>
          <div className="nav_inner_right">
            <div className="right_user_menu">
              <ul className="list-inline">
                <li className="chat-menu list-inline-item"></li>
                {loginDatas.length === 0 ? (
                  <>
                    <li className="chat-menu list-inline-item">
                      <LanguageHandler langData={langData}></LanguageHandler>
                    </li>
                    <li className="list-inline-item use_lett">
                      <Button
                        value={translation.logout}
                        buttonStyle="btn_outline w-auto"
                        width="104px"
                        onClick={() => handleLogout()}
                      />
                    </li>
                  </>
                ) : (
                  <>
                    <li className="chat-menu list-inline-item ">
                      <LanguageHandler langData={langData}></LanguageHandler>
                    </li>

                    <li className="list-inline-item use_lett">
                      <div style={{ position: "relative" }} className="">
                        <button
                          style={{
                            border: 0,
                            display: "inline",

                            width: 28,
                            padding: 0,
                          }}
                          className={
                            lang === "ara" ? "notify-icon_arab " : "notify-icon"
                          }
                          // onClick={() => setShowRightPopup(!showRightPopup)}
                          onClick={getNotification}
                        >
                          <img
                            src={TopHeaderbellicon}
                            alt=""
                            className="notify-icon bell"
                          />
                        </button>
                        {notificationCount > 0 && (
                          <sup
                            className={
                              lang === "ara"
                                ? "notification-badge-arab"
                                : "notification-badge"
                            }
                          >
                            {notificationCount > 0 && <>{notificationCount}</>}
                          </sup>
                        )}

                        <label
                          onClick={() => {
                            userIconClick();
                          }}
                          className="user-letter"
                        >
                          {loginDatas.profile_img === "" ? (
                            loginDatas.firstname.slice(0, 1) +
                            loginDatas.lastname.slice(0, 1)
                          ) : (
                            <img
                              src={s3UrlLink(loginDatas.profile_img)}
                              alt={loginData.firstname}
                            />
                          )}
                        </label>
                      </div>
                    </li>
                  </>
                )}

                {/* <Button
                  value={translation.logout}
                  buttonStyle="btn_outline w-auto"
                  width="104px"
                  onClick={() => handleLogout()}
                /> */}
              </ul>
            </div>
          </div>
        </div>

        {isOpened && (
          <div className={lang === "ara" ? "sidenav_left" : "sidenav_right"}>
            <div className="rightnavInner">
              <div className="row">
                <div className="col-sm-6">
                  <h6 className="left_title">{translation.myProfile}</h6>
                </div>
                <div className={`col-sm-6 ${lang === "ara" ? "text_end" : ""}`}>
                  <span
                    onClick={() => {
                      userIconClick();
                    }}
                    className="close_btn c_pointer"
                  >
                    &#10006;
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div
                    className={`pf_details  ${
                      lang == "ara" ? "text-center" : ""
                    }`}
                  >
                    <label
                      className={`user-letter large ${
                        lang === "ara" ? " mb_15im" : ""
                      }`}
                    >
                      {loginDatas.profile_img === "" ? (
                        loginDatas.firstname.slice(0, 1) +
                        loginDatas.lastname.slice(0, 1)
                      ) : (
                        <img
                          src={s3UrlLink(loginDatas.profile_img)}
                          alt={loginDatas.firstname}
                        />
                      )}
                      {/* { login.ing_firstname.slice(0, 1)} */}
                    </label>
                    <h4 className="text-capitalize">
                      {loginDatas.firstname + " " + loginDatas.lastname}
                    </h4>
                    {/* <button
                      type="button"
                      for="profileImage"
                      className="btn_outline w-50"
                    // onClick={handleShow}
                    >
                      {translation.upload_a_profile}
                    </button> */}
                    <label
                      for="profileImage"
                      className="btn_outline w-auto"
                      onClick={""}
                      style={{ lineHeight: "37px" }}
                    >
                      {translation.upload_a_profile}
                    </label>
                    <input
                      type="file"
                      name="profileImage"
                      id="profileImage"
                      hidden
                      style={{ display: "none" }}
                      onChange={(event) => {
                        setSelectedImage(event.target.files[0]);
                      }}
                      accept=".jpg,.jpeg,.png"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-4">
                  <p className="pf_left_btext">
                    <strong>{translation.Name}:</strong>
                  </p>
                </div>
                <div className="col-sm-8">
                  <p>{loginDatas.firstname + " " + loginDatas.lastname}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <p className="pf_left_btext">
                    <strong>{translation.akunah_id}:</strong>
                  </p>
                </div>
                <div className="col-sm-8">
                  <p>{loginDatas.ak_id}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <p className="pf_left_btext">
                    <strong>{translation.mobile}:</strong>
                  </p>
                </div>
                <div className="col-sm-8">
                  <p>{loginDatas.mobile}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <p className="pf_left_btext">
                    <strong>{translation.email}:</strong>
                  </p>
                </div>
                <div className="col-sm-8">
                  <p style={{ wordBreak: "break-all" }}>{loginDatas.email}</p>
                </div>
              </div>
              {/* <div className="row">
              <div className="col-sm-4">
                <p className="pf_left_btext">
                  <strong>{translation.localTime}:</strong>
                </p>
              </div>
              <div className="col-sm-8">
                {login.ing_timeformat === "2" && login.ing_timezone !== "" && (
                  <p>
                    <Clock
                      format={"HH:mm:ss"}
                      ticking={true}
                      timezone={login.ing_timezone}
                    />
                    
                  </p>
                )}
                {login.ing_timeformat === "1" && login.ing_timezone !== "" && (
                  <p>
                    <Clock
                      format={"h:mm A"}
                      ticking={true}
                      timezone={login.ing_timezone}
                    />
                  </p>
                )}
              </div>
            </div> */}

              <span className="signout_btn" onClick={() => handleLogout()}>
                <img className="ms-2" src={signOutIcon} alt="icon" />
                {translation.logout}
              </span>

              <span
                className={`${
                  lang === "ara" ? "switch_btn_ara" : "switch_btn"
                }`}
                onClick={() => handlerSwitch()}
              >
                <img className="ms-2" src={switchImg} alt="icon" />
                {translation.switch_profile}
              </span>

              {/* <div>
                <p
                  className="text-end c_pointer"
                  style={{ color: "#3f2783" }}
                  onClick={() => setModalOpenSmall(true)}
                >
                  {" "}
                  <b>Reset Password </b>
                </p>
              </div> */}
            </div>
          </div>
        )}
        {/* ---notification popup */}
        <Modal
          show={showRightPopup}
          onHide={handleClose}
          dialogClassName={
            lang === "ara" ? "notification_leftPop" : "notification_rightPop"
          }
        >
          <Modal.Body>
            {/* {showRightPopup && ( */}
            <div ref={popupRef}>
              <div className="notification_container_inner">
                <div className="notification_header d-flex align-items-center">
                  <div className="d-inline-block notification_title ">
                    {translation.Notifications}
                  </div>
                  {/* mark all read */}
                  {/* {notificationData?.length !== 0 && (
                    <button className="notify_btn" onClick={handleMarkAllRead}>
                      Mark all as read
                    </button>
                  )} */}
                </div>

                <div className="notification_body mt-3">
                  {notificationData?.length === 0 ? (
                    <div className="text-center border blank_notification">
                      <img
                        src={smiley}
                        alt="img"
                        className="notify_type_icon mb-4"
                        style={{ width: 35 }}
                      />
                      <p style={{ fontSize: 16, fontWeight: "bold" }}>
                        {translation.No_recent_notifications}
                      </p>
                      <p style={{ color: "#8F8F8F ", fontSize: 15 }}>
                        {translation.Have_a_good_day}
                      </p>
                    </div>
                  ) : (
                    notificationData &&
                    notificationData.map((ele) => (
                      <>
                        <div
                          className={`notify_item d-flex align-items-center ${
                            markAllRead
                              ? "notify_msg_read"
                              : "notify_msg_unread"
                          }`}

                          onClick={() =>
                                    handleOpen(
                                      ele.ak_dc,
                                      ele.ak_pt,
                                      ele.ak_ou,
                                      ele.type,
                                      ele.resource_id,
                                      ele.status
                                    )
                                  }
                        >
                          <div className="d-flex align-items-start">
                            <img
                              src={document}
                              alt="img"
                              className="me-4 notify_type_icon"
                            />
                            <div className="notification_type">
                              <div className="notify_msg">
                                <p
                                  className="d-inline"
                                  // onClick={() =>
                                  //   handleOpen(
                                  //     ele.ak_dc,
                                  //     ele.ak_pt,
                                  //     ele.ak_ou,
                                  //     ele.type,
                                  //     ele.resource_id,
                                  //     ele.status
                                  //   )
                                  // }
                                >
                                  <strong>
                                  Dr. {ele.doctor_name} {" "}
                                    {/* Dr. {ele.doctor_name} {translation.requested}{" "} */}
                                    {(() => {
                                      if (ele.type === 4) {
                                        // return "Shoulder";
                                        return translation.requested_shoulder_form
                                      } else if (ele.type === 6) {
                                        // return "Hip";
                                        return translation.requested_hip_form;
                                      } else if (ele.type === 21) {
                                        // return "Foot and Ankle";
                                        return translation.requested_foot_form;
                                      } else if (ele.type === 22) {
                                        // return "Elbow";
                                        return translation.requested_elbow_form;
                                      } else if (ele.type === 5) {
                                        // return "Knee";
                                        return translation.requested_knee_form
                                      } else if (ele.type === 23) {
                                        // return "Hand and Wrist";
                                        return translation.requested_hand_form;
                                      }else if (ele.type === 20) {
                                        // return "Hand and Wrist";
                                        return translation.requested_Paediatric_form;
                                      } else {
                                        // return "General";
                                        return translation.requested_General_form;
                                      }
                                    })()}{" "}
                                    
                                    {/* - {dateFuture(ele.date)}  */}
                                  </strong>
                                </p>
                                <p className="f_500">
                                  {getTimeDifference(ele.date)}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            className="right_icon d-flex"
                            onClick={() =>
                              handleOpen(
                                ele.ak_dc,
                                ele.ak_pt,
                                ele.ak_ou,
                                ele.type,
                                ele.resource_id,
                                ele.status
                              )
                            }
                          >
                            <img
                              src={arrowRight}
                              alt="img"
                              className="d-flex notify_item_right"
                            />
                          </div>
                        </div>
                      </>
                    ))
                  )}
                  {/* msg box */}
                  {/* <div
                  className={`notify_item d-flex align-items-center ${
                    markAllRead ? "notify_msg_read" : "notify_msg_unread"
                  }`}
                >
                  <div className="d-flex align-items-start">
                    <img src={document} alt="img" className="me-4 notify_type_icon" />
                    <div className="notification_type">
                      <div className="notify_msg">
                        <p className="d-inline">
                          <strong>{notificationData.proms_id}</strong> 
                        </p>
                      </div>
                      <div className="mt-2 notify_msg">2 hours ago</div>
                    </div>
                  </div>
                  <div className="right_icon d-flex">
                    <img
                      src={arrowRight}
                      alt="img"
                      className="d-flex notify_item_right"
                    />
                  </div>
                </div> */}
                  {/* <div
                  className={`notify_item d-flex align-items-center ${
                    markAllRead ? "notify_msg_read" : "notify_msg_unread"
                  }`}
                >
                  <div className="d-flex align-items-start">
                    <img src={document} alt="img" className="me-4 notify_type_icon" />
                    <div className="notification_type">
                      <div className="notify_msg">
                        <p className="d-inline">
                          <strong>John</strong> case has been
                        </p>
                      </div>
                      <div className="mt-2 notify_msg">2 hours ago</div>
                    </div>
                  </div>
                  <div className="right_icon d-flex">
                    <img
                      src={arrowRight}
                      alt="img"
                      className="d-flex notify_item_right"
                    />
                  </div>
                </div>
                <div
                  className={`notify_item d-flex align-items-center ${
                    markAllRead ? "notify_msg_read" : "notify_msg_unread"
                  }`}
                >
                  <div className="d-flex align-items-start">
                    <img src={tick} alt="img" className="me-4 notify_type_icon" />
                    <div className="notification_type">
                      <div className="notify_msg">
                        <p className="d-inline">
                          <strong>John</strong> case has been
                        </p>
                      </div>
                      <div className="mt-2 notify_msg">2 hours ago</div>
                    </div>
                  </div>
                  <div className="right_icon d-flex">
                    <img
                      src={arrowRight}
                      alt="img"
                      className="d-flex notify_item_right"
                    />
                  </div>
                </div>
                <div
                  className={`notify_item d-flex align-items-center ${
                    markAllRead ? "notify_msg_read" : "notify_msg_unread"
                  }`}
                >
                  <div className="d-flex align-items-start">
                    <img src={contact} alt="img" className="me-4 notify_type_icon" />
                    <div className="notification_type">
                      <div className="notify_msg">
                        <p className="d-inline">
                          <strong>John</strong> case has been
                        </p>
                      </div>
                      <div className="mt-2 notify_msg">2 hours ago</div>
                    </div>
                  </div>
                  <div className="right_icon d-flex">
                    <img
                      src={arrowRight}
                      alt="img"
                      className="d-flex notify_item_right"
                    />
                  </div>
                </div>
                 */}
                </div>
                {notificationData?.length >= 2 && (
                  <div className="notification_body mt-3">
                    <div className="text-center ">
                      <button
                        className="notify_btn mt-2"
                        onClick={() => handlecontinue()}
                      >
                        {translation.View_all}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default TopHeader;
