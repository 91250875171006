import React, { useEffect, useState } from "react";
import { useLanguageContext } from "./contexts/LanguageContext";
import { loginData } from "../redux/actions";
// import Dropdown from "./custom/dropdown/Dropdown";
import { useDispatch } from "react-redux";
import Dropdownno from "./custom/dropdown/Dropdownno";
import useAxios from "../axiosinstance";
import useTranslation from "./customHooks/translations";
import Message from "./custom/toster/Message";
export default function LanguageHandler({ langData }) {
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const dispatch = useDispatch();
  const { language, changeLanguage } = useLanguageContext();
  const axiosInstance = useAxios();
  // const login = useSelector((state) => state.loginPatient.arr[0]);
  let akToken = localStorage.getItem("AkTOKENI");
  let akLang = sessionStorage.getItem("lang_");
  const translation = useTranslation();

  // useEffect(() => {
  //   sessionStorage.setItem("lang_", login.patient.user_language);
  // }, [login]);
 
  const handleConnectMe = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getDetailsOfPatient?patient_id=${akToken}&use_for=patient`
      );
      if (result.status === 200) {
        if (result.data.status) {
          dispatch(loginData(result.data.data));
          setMessage(translation.profile_updated);
          setMessageType("success");
        } else {

        }
      }
    } catch (err) {

 

    } finally {
      
    }
  };
  // useEffect(()=>{
  //   handleConnectMe()
  // },[respondata])
  const [data, setData] = useState("")
  const handleChangeLanguage = (e) => {
    changeLanguage(e);
  
    sessionStorage.setItem("lang_", e);
    let lan = langData.filter((val) => val.value === e);
    setData(lan[0].id)
  };

  useEffect(() => {
    const Save = async () => {
      if (data) {

        await axiosInstance.current
          .get(`extapp/patients/saveUserLanguage/${akToken}?lang_id=${data}`)
          .then((res) => {
            if (res) {
              handleConnectMe()
              // setRespondata(new Date().getMilliseconds())
            }



          })
          .catch((err) => {
            setMessage(translation.profile_not_updated);
            setMessageType("error");
          });
        setTimeout(() => {
          setMessage(false);
        }, 3000);
      }
    }
    Save()
  }, [data])


  useEffect(() => {
    if (akLang !== null)
      changeLanguage(akLang)
  }, [akLang])
  return (
    <>
      <div className="lang_ ">
        {message && <Message message={message} type={messageType} />}
        <Dropdownno
          data={langData}
          label={""}
          styleClass="language_drp_dwn"
          value={language}
          onChange={handleChangeLanguage}
        />
        {/* <select value={language} onChange={(e) => changeLanguage(e.target.value)} className="form-control form-select">
           <option value="en">En- English </option>
           <option value="fr">Fr- France </option>
         </select> */}
      </div>
      {/* 
      <div className="lang_ d-block d-lg-none">
        <select value={language} onChange={(e) => changeLanguage(e.target.value)}>
          <option value="en">En </option>
          <option value="fr">Fr </option>
        </select>
      </div> */}
    </>
  );
}
